import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseUrl } from "../routes/BaseUrl";

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const year  = localStorage.getItem("year");
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    nama: "",
    startDate: "",
    endDate: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    if (!formData.nama) newErrors.nama = "Pilih Akun";
    if (!formData.startDate) newErrors.startDate = "Masukan Tanggal Mulai";
    if (!formData.endDate) newErrors.endDate = "Masukan Tanggal Akhir";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [roleoptions, setRoleOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(BaseUrl + `/api/users/list`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setOptions(response.data); // Assuming response.data is an array of options
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(BaseUrl + `/api/role/all`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setRoleOptions(response.data); // Assuming response.data is an array of options
       } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    const startDate = new Date(formData.startDate);
    const endDate = new Date(formData.endDate);

    // Calculate the difference in days
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert to days

   // Check if the difference exceeds 30 days
    if (diffDays > 31) {
     toast.error("Maksimal 31 hari.", {
        autoClose: 3000, // Close after 3 seconds
    });
    return;
   }

     const url = `/admin/report/suratmasuk/cetak?tujuan=${formData.nama}&start=${formData.startDate}&end=${formData.endDate}`;

      window.open(url, '_blank'); // Open the URL in a new tab
   
  };

  const CreateReportSuratMasuk = () => {
    return (
      <>
        <h5 className="mb-4">Laporan Surat Masuk</h5>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="nama">
              <Form.Label>Tujuan</Form.Label>
              <Form.Control
                required
                as="select"
                value={formData.nama}
                onChange={(e) =>
                  setFormData({ ...formData, nama: e.target.value })
                }
                isInvalid={!!errors.nama}
              >
                <option value="">Pilih Akun</option>
                 {options.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                    {option.periode.length > 0
                      ? option.periode.map((periode) => (
                          <div key={periode.id}> [ {periode.role.nama} ]</div>
                        ))
                      : " - "}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.nama}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="startDate">
              <Form.Label>Tanggal Mulai</Form.Label>
              <Form.Control
                type="date"
                placeholder="Tanggal Mulai"
                value={formData.startDate}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    startDate: e.target.value,
                  })
                }
                isInvalid={!!errors.startDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.startDate}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="endDate">
              <Form.Label>
                Tanggal Akhir 
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="Tanggal Akhir"
                value={formData.endDate}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    endDate: e.target.value,
                  })
                }
                isInvalid={!!errors.endDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.endDate}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="mt-3">
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleFormSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Cetak"
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleFormSubmit}>{CreateReportSuratMasuk()}</Form>
      </Card.Body>
    </Card>
  );
};
