import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Button, Col, Alert } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BaseUrl, SuratMasukUpload } from "../../routes/BaseUrl";
import { DisposisiInTable } from "../../components/AccountTables";
import axios from "axios";

export default () => {
  const { id } = useParams();
  
  const [suratDetails, setSuratDetails] = useState(null);
  
   useEffect(() => {
    const token = localStorage.getItem("token");
    const fetchSuratDetails = async () => {
      try {
        const response = await axios.get(
          BaseUrl + `/api/suratmasuk/show/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const surat = response.data;
        console.log(response.data);
        setSuratDetails(surat);
      } catch (error) {
        console.error("Error fetching surat details:", error);
      }
    };
    fetchSuratDetails();
  }, [id]);
  
    if (!suratDetails) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/user/suratmasuk/view/${id}`}>Surat Masuk</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Disposisi </Breadcrumb.Item>
          </Breadcrumb>
          <h4>Disposisi Masuk</h4>
           <div style={{ maxWidth: '600px', whiteSpace: 'normal', overflowWrap: 'break-word' }}>
                 <Alert variant="info">{suratDetails.isiRingkas}</Alert>
           </div>
        </div>
      </div>

      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col
          xs="auto"
          className="d-flex justify-content-between ps-0 mb-4 mb-lg-0"
        >
          <div className="me-lg-3">
            <Button
              as={Link}
              to={`/user/disposisi/create/${id}`} // Use template literals here
              variant="secondary"
              className="text-dark me-2"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              <span>Tambah Disposisi</span>
            </Button>
          </div>
        </Col>
      </div>

      <DisposisiInTable />
    </>
  );
};
