import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseUrl } from "../routes/BaseUrl";

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const year = localStorage.getItem("year");
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    nama: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const userId = localStorage.getItem("userId");
  const accessType = localStorage.getItem("accessType");

  const validateForm = () => {
    const newErrors = {};

    if (!formData.nama) newErrors.nama = "Masukan Nama Akun";
    if (!formData.email) newErrors.email = "Masukan Email Akun";

    // Only check for confirmation password if password is filled
    if (formData.password && !formData.cpassword) {
      newErrors.cpassword = "Masukan Konfirmasi Password";
    } else if (formData.password !== formData.cpassword) {
      newErrors.cpassword = "Password dan konfirmasi harus sama";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchUserAccount = async () => {
      try {
        const response = await axios.get(
          BaseUrl + `/api/users/show/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace with your actual token
            },
          }
        );
        setFormData(response.data); // Assuming response.data is an array of options
      } catch (error) {
        console.error("Error fetching user account:", error);
      }
    };

    fetchUserAccount();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await axios.post(
        BaseUrl + `/api/users/update/${userId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add your token here
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        if (accessType === "admin") {
          Navigate(`/admin/setting/account`);
        } else {
          Navigate(`/user/setting/account`);
        }
      } else if (response.data.status == "failed") {
        toast.error(response.data.message);
        if (accessType === "admin") {
          Navigate(`/admin/setting/account`);
        } else {
          Navigate(`/user/setting/account`);
        }
      } else {
        toast.error(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const settingAccount = () => {
    return (
      <>
        <h5 className="mb-4">Setting Akun</h5>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="nama">
              <Form.Label>Nama Akun</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Nama Akun"
                value={formData.nama}
                onChange={(e) =>
                  setFormData({ ...formData, nama: e.target.value })
                }
                isInvalid={!!errors.nama}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nama}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Masukan Email "
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Password (hanya jika ingin ganti password)"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="cpassword">
              <Form.Label>Konfirmasi Password</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Konfirmasi Password"
                value={formData.cpassword}
                onChange={(e) =>
                  setFormData({ ...formData, cpassword: e.target.value })
                }
                isInvalid={!!errors.cpassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cpassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="mt-3">
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleFormSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Simpan"
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleFormSubmit}>{settingAccount()}</Form>
      </Card.Body>
    </Card>
  );
};
