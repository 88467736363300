import { faFilePdf, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Card, Table } from "@themesberg/react-bootstrap";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import DocumentViewer from "../../components/DocumentViewer";
import { BaseUrl, SuratMasukUpload } from "../../routes/BaseUrl";

export default () => {
    const { id } = useParams();
    const [suratDetails, setSuratDetails] = useState(null);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [file, setFile] = useState(null);

    const handleOpenViewer = (file) => {
        setFile(file);
        setIsViewerOpen(true);
    };

    const handleCloseViewer = () => {
        setIsViewerOpen(false);
        setFile(null);
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const fetchSuratDetails = async () => {
            try {
                const response = await axios.get(
                    BaseUrl + `/api/user/suratmasuk/show/${id}?user=${userId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const surat = response.data;
                setSuratDetails(surat);
            } catch (error) {
                console.error("Error fetching surat details:", error);
            }
        };
        fetchSuratDetails();
    }, [id]);

    if (!suratDetails) {
        return <div>Loading...</div>;
    }

    const fullFilePath = `${SuratMasukUpload}${suratDetails.fileSurat}`;

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block"
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <Link to="/user/suratmasuk/list">Surat Masuk</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {suratDetails.noAgenda}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Surat Masuk Detail</h4>
                </div>
            </div>

            <Card border="light" className="bg-white shadow-sm mb-4">
                <Card.Body>
                    <div style={{ overflowX: "auto" }}>
                        {" "}
                        {/* Ensure the table is scrollable on mobile */}
                        <Table hover className="mb-8 align-items-left">
                            <tbody>
                                <tr>
                                    <td>
                                        <strong>Nomor Agenda</strong>
                                    </td>
                                    <td>{suratDetails.noAgenda}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Asal Surat</strong>
                                    </td>
                                    <td>{suratDetails.asalSurat}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Nomor Surat</strong>
                                    </td>
                                    <td>{suratDetails.nomorSurat}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Perihal</strong>
                                    </td>
                                    <td>{suratDetails.perihal}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Isi Ringkas</strong>
                                    </td>
                                    <td
                                        style={{
                                            maxWidth: "100%",
                                            whiteSpace: "normal",
                                            overflowWrap: "break-word",
                                        }}
                                    >
                                        {suratDetails.isiRingkas}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Sifat Surat</strong>
                                    </td>
                                    <td>{suratDetails.sifatSurat}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Tujuan Surat</strong>
                                    </td>
                                    <td>
                                        {suratDetails.tujuanSuratNama}{" "}
                                        {suratDetails.tujuanRoleNama &&
                                            `[${suratDetails.tujuanRoleNama}]`}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <strong>Tanggal Cetak</strong>
                                    </td>
                                    <td>
                                        {moment(
                                            suratDetails.tanggalCetak
                                        ).format("DD MMMM YYYY")}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Kode Klasifikasi</strong>
                                    </td>
                                    <td>{suratDetails.kodeKlasifikasi}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Indeks Berkas</strong>
                                    </td>
                                    <td
                                        style={{
                                            maxWidth: "200px",
                                            whiteSpace: "normal",
                                            overflowWrap: "break-word",
                                        }}
                                    >
                                        {suratDetails.indeksBerkas}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Tanggal Surat</strong>
                                    </td>
                                    <td>
                                        {moment(
                                            suratDetails.tanggalSurat
                                        ).format("DD MMMM YYYY")}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Diterima Tanggal</strong>
                                    </td>
                                    <td>
                                        {moment(
                                            suratDetails.tanggalDiterima
                                        ).format("DD MMMM YYYY")}
                                    </td>
                                </tr>
                                {suratDetails.fileSurat && (
                                    <tr>
                                        <td>File Surat</td>
                                        <td>
                                            <Link
                                                onClick={() =>
                                                    handleOpenViewer(
                                                        fullFilePath
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faFilePdf}
                                                    className="me-2"
                                                />
                                            </Link>
                                        </td>
                                    </tr>
                                )}
                                <tr>
                                    <td>
                                        <strong>Keterangan</strong>
                                    </td>
                                    <td>
                                        {suratDetails.keterangan
                                            ? suratDetails.keterangan
                                            : "-"}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Nama Penerima</strong>
                                    </td>
                                    <td>
                                        {suratDetails.namaPenerima
                                            ? suratDetails.namaPenerima
                                            : "-"}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Nomor Telp Penerima</strong>
                                    </td>
                                    <td>
                                        {suratDetails.noTelpPenerima
                                            ? suratDetails.noTelpPenerima
                                            : "-"}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    {isViewerOpen && (
                        <DocumentViewer
                            file={file}
                            onClose={handleCloseViewer}
                        />
                    )}
                </Card.Body>
            </Card>
        </>
    );
};
