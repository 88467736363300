import React from "react";
import { GeneralInfoForm } from "../../components/ReportSuratKeluar";

export default () => {
  return (
    <>
      <GeneralInfoForm />
    </>
  );
};
