import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Col, Row } from "@themesberg/react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { GeneralInfoForm } from "../../components/CreateUserRole";

export default () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
             <Link to="/admin/users/role">Jabatan</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Tambah Jabatan</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <Row className="mb-5">
        <Col xs={12} xl={12}>
          <GeneralInfoForm />
        </Col>
      </Row>
    </>
  );
};
