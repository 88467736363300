import {
  faPlus,
  faEnvelope,
  faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Dropdown } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

import { BaseUrl } from "../../routes/BaseUrl";

// Register chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default () => {
  const [chartData, setChartData] = useState(null);
  const [monthLabels, setMonthLabels] = useState([]);
  const [loading, setLoading] = useState(false);

  const year = localStorage.getItem("year");
  const token = localStorage.getItem("token");

  useEffect(() => {
    // Fetch chart data from Laravel backend
    setLoading(true);
    axios
      .get(BaseUrl + `/api/dashboard/chart?year=${year}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Set the Authorization header with Bearer token
        },
      })
      .then((response) => {
        const { chart, month } = response.data;

        // Set chart data and month labels
        setChartData({
          labels: month || [],
          datasets: [
            {
              label: "Surat Masuk",
              data: chart.surat_masuk,
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              fill: true,
            },
            {
              label: "Surat Keluar",
              data: chart.surat_keluar,
              borderColor: "rgba(153, 102, 255, 1)",
              backgroundColor: "rgba(153, 102, 255, 0.2)",
              fill: true,
            },
          ],
        });

        setMonthLabels(month);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chart data:", error);
      });
  }, []);
  
  
  if (!chartData) {
  return <Skeleton count={7} height={50} />;
}
  
  

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Dropdown className="btn-toolbar">
          <Dropdown.Toggle
            as={Button}
            variant="primary"
            size="sm"
            className="me-2"
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Tambah Surat
          </Dropdown.Toggle>
          <Dropdown.Menu className="dashboard-dropdown dropdown-menu-left mt-2">
           <Dropdown.Item as={Link} to="/admin/suratmasuk/create" className="fw-bold">
             <FontAwesomeIcon icon={faEnvelope} className="me-2" /> Surat Masuk
             </Dropdown.Item>
             <Dropdown.Item as={Link} to="/admin/suratkeluar/create" className="fw-bold">
             <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Surat Keluar
             </Dropdown.Item>
            <Dropdown.Divider />
          </Dropdown.Menu>
        </Dropdown>
      </div>

     {loading ? (
      <Skeleton count={7} height={50} />
    ) : ( 
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <div className="chart-container" style={{ position: "relative", height: "50vh", width: "100%" }}>
            <Line
              data={chartData}
                options={{
                maintainAspectRatio: false,
                responsive: true, 
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: "Surat Masuk dan Surat Keluar Tahun " + year,
                  },
                },
              }}
            />
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
