import {
  faArrowDown,
  faArrowUp,
  faEdit,
  faEllipsisH,
  faEye,
  faFilePdf,
  faPen,
  faPrint,
  faSearch,
  faShare,
  faTrashAlt,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Nav,
  Pagination,
  Table,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  BaseUrl,
  SuratKeluarUpload,
  SuratMasukUpload,
} from "../routes/BaseUrl";
import CommonDeleteModal from "./CommonDeleteModal";
import DeleteDispModal from "./DeleteDispModal";
import DocumentViewer from "./DocumentViewer";

export const UserInboxTable = () => {
  moment.locale("id");
  const [suratmasuk, setSuratMasuk] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);

  const handleOpenViewer = async (file, suratId, userId) => {
    const token = localStorage.getItem("token");
    try {
      // Call the API to mark the letter as read
      const response = await axios.post(
        `${BaseUrl}/api/user/suratmasuk/markread`,
        {
          user_id: userId,
          surat_id: suratId,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set Content-Type to application/json
            Authorization: `Bearer ${token}`, // Add Bearer token to the headers
          },
        }
      );

      if (response.status === 200) {
        // Marked as read successfully, now open the viewer
        setFile(file);
        setIsViewerOpen(true);
      } else {
        console.error("Failed to mark as read");
      }
    } catch (error) {
      console.error("Error marking letter as read:", error);
    }
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratMasuk = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    const userId = localStorage.getItem("userId");

    try {
      const response = await axios.get(
        `${BaseUrl}/api/user/suratmasuk/inbox?user=${userId}&year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      // Map through data to include user.name
      const suratMasukWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
      }));

      setSuratMasuk(suratMasukWithUser);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat masuk:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratMasuk();
  }, [currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratMasuk();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const checkDisposisi = async (suratId) => {
    const userId = localStorage.getItem("userId");
    try {
      const token = localStorage.getItem("token"); // Assuming you store the token in localStorage
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/check/${suratId}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token to the headers
          },
        }
      );
      return response.data.hasDisposisi; // Assuming response API contains boolean `hasDisposisi`
    } catch (error) {
      console.error("Error checking disposisi", error);
      return false;
    }
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const [hasDisposisi, setHasDisposisi] = useState(false);
    const userId = localStorage.getItem("userId");

    useEffect(() => {
      const fetchDisposisiStatus = async () => {
        const status = await checkDisposisi(surat.id);
        setHasDisposisi(status);
      };

      fetchDisposisiStatus();
    }, [surat.id]);
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
      nama_role,
      is_read,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratMasukUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath, surat.id, userId)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  title={surat.is_read ? "Sudah dibaca" : "Lihat Surat"} // Change title based on is_read
                  style={{ color: surat.is_read ? "red" : "black" }} // Color based on is_read
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowDown} className="me-2" /> {dari}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {surat.is_read && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="me-2"
                        title="Sudah dibaca" // Optional title for the tick icon
                        style={{ color: "green" }} // Color for the tick symbol
                      />
                    )}
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                </div>
                {hasDisposisi ? (
                  <div className="ms-sm-3">
                    <FontAwesomeIcon
                      icon={faShare}
                      title="Disposisi Keluar"
                      className="me-2"
                      style={{ color: "blue" }}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-block d-lg-block d-xl-inline-flex align-items-center ms-lg-auto px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/user/suratmasuk/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/track/${id}`}
                  className="ms-0"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSearch} className="me-2" /> Lacak
                  Disposisi
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/keluar/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Disposisi
                  Keluar
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/cetak/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Disposisi
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratmasuk.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratmasuk.map((surat, index) => (
                <TableRow key={surat.id} surat={surat} index={index} />
              ))}
            </div>
          )}
          {suratmasuk.length !== 0 ? (
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>

              <small className="fw-bold">
                Showing <b>{suratmasuk.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          ) : null}

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/user/suratmasuk/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};

export const UserInboxSearchTable = () => {
  moment.locale("id");
  const [suratmasuk, setSuratMasuk] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query");

  const handleOpenViewer = async (file, suratId, userId) => {
    const token = localStorage.getItem("token");
    try {
      // Call the API to mark the letter as read
      const response = await axios.post(
        `${BaseUrl}/api/user/suratmasuk/markread`,
        {
          user_id: userId,
          surat_id: suratId,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set Content-Type to application/json
            Authorization: `Bearer ${token}`, // Add Bearer token to the headers
          },
        }
      );

      if (response.status === 200) {
        // Marked as read successfully, now open the viewer
        setFile(file);
        setIsViewerOpen(true);
      } else {
        console.error("Failed to mark as read");
      }
    } catch (error) {
      console.error("Error marking letter as read:", error);
    }
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratMasuk = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    const userId = localStorage.getItem("userId");

    try {
      const response = await axios.get(
        `${BaseUrl}/api/user/suratmasuk/inbox/search?query=${searchQuery}&user=${userId}&year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      // Map through data to include user.name
      const suratMasukWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
      }));

      setSuratMasuk(suratMasukWithUser);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat masuk:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratMasuk();
  }, [searchQuery, currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratMasuk();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const checkDisposisi = async (suratId) => {
    const userId = localStorage.getItem("userId");
    try {
      const token = localStorage.getItem("token"); // Assuming you store the token in localStorage
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/check/${suratId}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token to the headers
          },
        }
      );
      return response.data.hasDisposisi; // Assuming response API contains boolean `hasDisposisi`
    } catch (error) {
      console.error("Error checking disposisi", error);
      return false;
    }
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const [hasDisposisi, setHasDisposisi] = useState(false);
    const userId = localStorage.getItem("userId");

    useEffect(() => {
      const fetchDisposisiStatus = async () => {
        const status = await checkDisposisi(surat.id);
        setHasDisposisi(status);
      };

      fetchDisposisiStatus();
    }, [surat.id]);
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
      nama_role,
      is_read,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratMasukUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath, surat.id, userId)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  title={surat.is_read ? "Sudah dibaca" : "Lihat Surat"} // Change title based on is_read
                  style={{ color: surat.is_read ? "red" : "black" }} // Color based on is_read
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowDown} className="me-2" /> {dari}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {surat.is_read && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="me-2"
                        title="Sudah dibaca" // Optional title for the tick icon
                        style={{ color: "green" }} // Color for the tick symbol
                      />
                    )}
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                </div>
                {hasDisposisi ? (
                  <div className="ms-sm-3">
                    <FontAwesomeIcon
                      icon={faShare}
                      title="Disposisi Keluar"
                      className="me-2"
                      style={{ color: "blue" }}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-block d-lg-block d-xl-inline-flex align-items-center ms-lg-auto px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/user/suratmasuk/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/track/${id}`}
                  className="ms-0"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSearch} className="me-2" /> Lacak
                  Disposisi
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/keluar/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Disposisi
                  Keluar
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/cetak/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Disposisi
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratmasuk.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratmasuk.map((surat, index) => (
                <TableRow key={surat.id} surat={surat} index={index} />
              ))}
            </div>
          )}
          {suratmasuk.length !== 0 ? (
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>

              <small className="fw-bold">
                Showing <b>{suratmasuk.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          ) : null}

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/user/suratmasuk/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};


export const UserSuratMasukTable = () => {
  moment.locale("id");
  const [suratmasuk, setSuratMasuk] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [disposisiStatus, setDisposisiStatus] = useState({});

  const handleOpenViewer = async (file, suratId, userId) => {
    const token = localStorage.getItem("token");
    try {
      // Call the API to mark the letter as read
      const response = await axios.post(
        `${BaseUrl}/api/user/suratmasuk/markread`,
        {
          user_id: userId,
          surat_id: suratId,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set Content-Type to application/json
            Authorization: `Bearer ${token}`, // Add Bearer token to the headers
          },
        }
      );

      if (response.status === 200) {
        // Marked as read successfully, now open the viewer
        setFile(file);
        setIsViewerOpen(true);
      } else {
        console.error("Failed to mark as read");
      }
    } catch (error) {
      console.error("Error marking letter as read:", error);
    }
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratMasuk = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    const userId = localStorage.getItem("userId");

    try {
      const response = await axios.get(
        `${BaseUrl}/api/user/suratmasuk/list?user=${userId}&year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      // Map through data to include user.name
      const suratMasukWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
      }));

      setSuratMasuk(suratMasukWithUser);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat masuk:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratMasuk();
  }, [currentPage]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratMasuk();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const checkDisposisi = async (suratId) => {
    const userId = localStorage.getItem("userId");
    try {
      const token = localStorage.getItem("token"); // Assuming you store the token in localStorage
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/check/${suratId}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token to the headers
          },
        }
      );
      return response.data.hasDisposisi; // Assuming response API contains boolean `hasDisposisi`
    } catch (error) {
      console.error("Error checking disposisi", error);
      return false;
    }
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const [hasDisposisi, setHasDisposisi] = useState(false);
    const userId = localStorage.getItem("userId");

    useEffect(() => {
      const fetchDisposisiStatus = async () => {
        const status = await checkDisposisi(surat.id);
        setHasDisposisi(status);
      };

      fetchDisposisiStatus();
    }, [surat.id]);
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
      nama_role,
      is_read,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratMasukUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath, surat.id, userId)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  title="Lihat Surat"
                  // Change color based on the is_read flag
                  style={{ color: surat.is_read ? "red" : "black" }} // If read, gray; otherwise, black
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowDown} className="me-2" /> {dari}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {surat.is_read && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="me-2"
                        title="Sudah dibaca" // Optional title for the tick icon
                        style={{ color: "green" }} // Color for the tick symbol
                      />
                    )}
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                </div>
                {hasDisposisi ? (
                  <div className="ms-sm-3">
                    <FontAwesomeIcon
                      icon={faShare}
                      title="Disposisi Keluar"
                      style={{ color: "blue" }}
                      className="me-2"
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-block d-lg-block d-xl-inline-flex align-items-center ms-lg-auto px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/user/suratmasuk/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>

                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/keluar/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Disposisi
                  Keluar
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/masuk/${id}`}
                  className="ms-0"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPen} className="me-2" /> Disposisi
                  Masuk
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/track/${id}`}
                  className="ms-0"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSearch} className="me-2" /> Lacak
                  Disposisi
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/cetak/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Disposisi
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratmasuk.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratmasuk.map((surat, index) => (
                <TableRow key={surat.id} surat={surat} index={index} />
              ))}
            </div>
          )}
          {suratmasuk.length > 0 ? (
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{suratmasuk.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          ) : null}

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/user/suratmasuk/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};


export const UserSuratMasukSearchTable = () => {
  moment.locale("id");
  const [suratmasuk, setSuratMasuk] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query");

  const handleOpenViewer = (file) => {
    setFile(file);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratMasuk = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    const userId = localStorage.getItem("userId");

    try {
      const response = await axios.get(
        `${BaseUrl}/api/user/suratmasuk/search?query=${searchQuery}&user=${userId}&year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      // Map through data to include user.name
      const suratMasukWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
      }));

      setSuratMasuk(suratMasukWithUser);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat masuk:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratMasuk();
  }, [searchQuery, currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratMasuk();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const checkDisposisi = async (suratId) => {
    const userId = localStorage.getItem("userId");
    try {
      const token = localStorage.getItem("token"); // Assuming you store the token in localStorage
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/check/${suratId}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Bearer token to the headers
          },
        }
      );
      return response.data.hasDisposisi; // Assuming response API contains boolean `hasDisposisi`
    } catch (error) {
      console.error("Error checking disposisi", error);
      return false;
    }
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const [hasDisposisi, setHasDisposisi] = useState(false);

    useEffect(() => {
      const fetchDisposisiStatus = async () => {
        const status = await checkDisposisi(surat.id);
        setHasDisposisi(status);
      };

      fetchDisposisiStatus();
    }, [surat.id]);
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
      nama_role,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratMasukUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  title="Lihat Surat"
                  style={{ color: "red" }}
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowDown} className="me-2" /> {dari}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                </div>
                {hasDisposisi ? (
                  <div className="ms-sm-3">
                    <FontAwesomeIcon
                      icon={faShare}
                      title="Disposisi Keluar"
                      className="me-2"
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-block d-lg-block d-xl-inline-flex align-items-center ms-lg-auto px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/user/suratmasuk/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/track/${id}`}
                  className="ms-0"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSearch} className="me-2" /> Lacak
                  Disposisi
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/keluar/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Disposisi
                  Keluar
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/user/disposisi/cetak/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Disposisi
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratmasuk.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratmasuk.map((surat, index) => (
                <TableRow key={surat.id} surat={surat} index={index} />
              ))}
            </div>
          )}
          {suratmasuk.length !== 0 ? (
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>

              <small className="fw-bold">
                Showing <b>{suratmasuk.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          ) : null}

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/user/suratmasuk/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};
