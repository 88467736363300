import { faHome, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Col, Form, InputGroup } from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { KlasifikasiTable } from "../../components/Tables";

export default () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchQuery.trim()) {
      // Navigate to the results page with the query as a parameter
      navigate(
        `/admin/klasifikasi/search?query=${encodeURIComponent(searchQuery)}`
      );
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
               <Link to="/admin/klasifikasi/list">Klasifikasi</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Daftar Klasifikasi </Breadcrumb.Item>
          </Breadcrumb>
          <h4>Daftar Klasifikasi Surat</h4>
        </div>
      </div>

      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col
          xs="auto"
          className="d-flex justify-content-between ps-0 mb-4 mb-lg-0"
        ></Col>

        <Col xs={12} lg={3} className="px-0 mb-0">
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Pencarian Klasifikasi"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </InputGroup>
        </Col>
      </div>

      <KlasifikasiTable />
    </>
  );
};
