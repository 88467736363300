import {
  faArrowDown,
  faArrowUp,
  faEdit,
  faEllipsisH,
  faEye,
  faFilePdf,
  faPen,
  faPrint,
  faSearch,
  faShare,
  faTrashAlt,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Nav,
  Pagination,
  Table,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  BaseUrl,
  SuratKeluarUpload,
  SuratMasukUpload,
} from "../routes/BaseUrl";
import CommonDeleteModal from "./CommonDeleteModal";
import DeleteDispModal from "./DeleteDispModal";
import DocumentViewer from "./DocumentViewer";

export const RoleTable = () => {
  const [role, setRole] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const itemsPerPage = 10;

  const fetchRole = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/role/list?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      setRole(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching role:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRole();
  }, [currentPage]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedRoleId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchRole();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ role, index }) => {
    const { id, nama } = role;

    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>{role.nama}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/admin/users/role/edit/${id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(role.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Nama</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {role.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {role.map((role, index) => (
                    <TableRow key={index} role={role} index={index} />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{role.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedRoleId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/role/delete`}
            />
          )}
        </Card>
      )}
    </>
  );
};

export const AccountTable = () => {
  const [account, setAccount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const itemsPerPage = 10;

  const fetchAccount = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/users/listpaginate?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      setAccount(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching role:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccount();
  }, [currentPage]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedAccountId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchAccount();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ account, index }) => {
    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>{account.name} </td>
        <td>{account.email}</td>
        <td>{account.hak_akses}</td>
        <td>
          {account.periode.length > 0
            ? account.periode.map((periode) => (
                <div key={periode.id}>{periode.role.nama}</div>
              ))
            : "-"}
        </td>
        <td>{account.status}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/admin/users/account/edit/${account.id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(account.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
         <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Nama</th>
                  <th className="border-bottom">Email</th>
                  <th className="border-bottom">Hak Akses</th>
                  <th className="border-bottom">
                    Jabatan Aktif (dari periode)
                  </th>
                  <th className="border-bottom">Status</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {account.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {account.map((account, index) => (
                    <TableRow
                      key={account.id}
                      account={account}
                      index={index}
                    />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{account.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedAccountId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/users/delete`}
            />
          )}
        </Card>
      )}
    </>
  );
};

export const AccountSearchTable = () => {
  const [account, setAccount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const itemsPerPage = 10;
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query");

  const fetchAccount = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/users/search?query=${searchQuery}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      setAccount(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching role:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccount();
  }, [currentPage,searchQuery]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedAccountId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchAccount();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ account, index }) => {
    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>{account.name} </td>
        <td>{account.email}</td>
        <td>{account.hak_akses}</td>
        <td>
          {account.periode.length > 0
            ? account.periode.map((periode) => (
                <div key={periode.id}>{periode.role.nama}</div>
              ))
            : "-"}
        </td>
        <td>{account.status}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/admin/users/account/edit/${account.id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(account.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
         <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Nama</th>
                  <th className="border-bottom">Email</th>
                  <th className="border-bottom">Hak Akses</th>
                  <th className="border-bottom">
                    Jabatan Aktif (dari periode)
                  </th>
                  <th className="border-bottom">Status</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {account.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {account.map((account, index) => (
                    <TableRow
                      key={account.id}
                      account={account}
                      index={index}
                    />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{account.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedAccountId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/users/delete`}
            />
          )}
        </Card>
      )}
    </>
  );
};

export const PeriodeTable = () => {
  const [periode, setPeriode] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const itemsPerPage = 10;

  const fetchPeriode = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/periode/list?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      setPeriode(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching role:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPeriode();
  }, [currentPage]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedAccountId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchPeriode();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ periode, index }) => {
    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>{periode.user.name}</td>
        <td>{periode.role.nama}</td>
        <td>
          {periode.start_date
            ? moment(periode.start_date).format("DD MMMM YYYY")
            : "-"}
        </td>
        <td>
          {periode.end_date
            ? moment(periode.end_date).format("DD MMMM YYYY")
            : "-"}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/admin/users/periode/edit/${periode.id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(periode.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
         <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Nama</th>
                  <th className="border-bottom">Jabatan</th>
                  <th className="border-bottom">Tanggal Mulai</th>
                  <th className="border-bottom">Tanggal Akhir</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {periode.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {periode.map((periode, index) => (
                    <TableRow
                      key={periode.id}
                      periode={periode}
                      index={index}
                    />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{periode.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedAccountId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/periode/delete`}
            />
          )}
        </Card>
      )}
    </>
  );
};


export const PeriodeSearchTable = () => {
  const [periode, setPeriode] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const itemsPerPage = 10;
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query");

  const fetchPeriode = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/periode/search?query=${searchQuery}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      console.log(response);
      setPeriode(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching periode:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPeriode();
  }, [currentPage,searchQuery]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedAccountId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchPeriode();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ periode, index }) => {
    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>{periode.user.name}</td>
        <td>{periode.role.nama}</td>
        <td>
          {periode.start_date
            ? moment(periode.start_date).format("DD MMMM YYYY")
            : "-"}
        </td>
        <td>
          {periode.end_date
            ? moment(periode.end_date).format("DD MMMM YYYY")
            : "-"}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/admin/users/periode/edit/${periode.id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(periode.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
         <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Nama</th>
                  <th className="border-bottom">Jabatan</th>
                  <th className="border-bottom">Tanggal Mulai</th>
                  <th className="border-bottom">Tanggal Akhir</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {periode.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {periode.map((periode, index) => (
                    <TableRow
                      key={periode.id}
                      periode={periode}
                      index={index}
                    />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{periode.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedAccountId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/periode/delete`}
            />
          )}
        </Card>
      )}
    </>
  );
};

export const DisposisiAccessTable = () => {
  const [access, setAccess] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const itemsPerPage = 10;

  const fetchAccess = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/acclist?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      setAccess(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching role:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccess();
  }, [currentPage]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedAccountId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchAccess();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ access, index }) => {
    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>
          {access.pemberi.name}
          {access.pemberi.periode_jabatan && access.pemberi.periode_jabatan.role
            ? `[${access.pemberi.periode_jabatan.role.nama}]`
            : "Role tidak ditemukan"}
        </td>

        <td>
          {access.penerima.name}
          {access.penerima.periode_jabatan &&
          access.penerima.periode_jabatan.role
            ? `[${access.penerima.periode_jabatan.role.nama}]`
            : "Role tidak ditemukan"}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/admin/users/disposisi/edit/${access.id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(access.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
         <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Pembuat Disposisi</th>
                  <th className="border-bottom">Penerima Disposisi</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {access.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {access.map((access, index) => (
                    <TableRow key={access.id} access={access} index={index} />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{access.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedAccountId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/disposisi/accdelete`}
            />
          )}
        </Card>
      )}
    </>
  );
};

export const DisposisiAccessSearchTable = () => {
  const [access, setAccess] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const itemsPerPage = 10;
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query");

  const fetchAccess = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/searchacclist?query=${searchQuery}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      setAccess(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching role:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAccess();
  }, [currentPage,searchQuery]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedAccountId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchAccess();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ access, index }) => {
    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>
          {access.pemberi.name}
          {access.pemberi.periode_jabatan && access.pemberi.periode_jabatan.role
            ? `[${access.pemberi.periode_jabatan.role.nama}]`
            : "Role tidak ditemukan"}
        </td>

        <td>
          {access.penerima.name}
          {access.penerima.periode_jabatan &&
          access.penerima.periode_jabatan.role
            ? `[${access.penerima.periode_jabatan.role.nama}]`
            : "Role tidak ditemukan"}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/admin/users/disposisi/edit/${access.id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(access.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
         <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Pembuat Disposisi</th>
                  <th className="border-bottom">Penerima Disposisi</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {access.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {access.map((access, index) => (
                    <TableRow key={access.id} access={access} index={index} />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{access.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedAccountId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/disposisi/accdelete`}
            />
          )}
        </Card>
      )}
    </>
  );
};

export const DisposisiInTable = () => {
  const { id } = useParams();
  const [disposisi, setDisposisi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDisposisiId, setSelectedDisposisiId] = useState(null);
  const itemsPerPage = 10;

  const fetchDisposisi = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/showin/${id}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //const { data, totalPages } = response.data;
      setDisposisi(response.data.data);
    } catch (error) {
      console.error("Error fetching disposisi:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDisposisi();
  }, []);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedDisposisiId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    await fetchDisposisi();
  };

  const TableRow = ({ disposisi, index }) => {
    //const { id, kode, nama } = disposisi;

    return (
      <tr>
        <td>{index + 1}</td> {/* Menampilkan nomor urut */}
        <td>
          {disposisi.nama_pengirim && disposisi.role
            ? `${disposisi.nama_pengirim} ${
                disposisi.role ? ` [${disposisi.role}]` : ""
              }`
            : "N/A"}
        </td>
        <td>{moment(disposisi.created_at).format("DD MMMM YYYY, HH:mm ")}</td>
        <td>{disposisi.terbaca === "Y" ? "Sudah Dibaca" : "Belum Dibaca"}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/user/disposisi/view/${disposisi.id}/${disposisi.surat_id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEye} className="me-2" /> Baca Disposisi
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
         <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No</th>
                  <th className="border-bottom">Asal Disposisi</th>
                  <th className="border-bottom">Dikirim Pada</th>
                  <th className="border-bottom">Status</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>

              <tbody>
                {disposisi.map((disposisi, index) => (
                  <TableRow key={index} disposisi={disposisi} index={index} />
                ))}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5"></Card.Footer>
          </Card.Body>
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedDisposisiId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/disposisi/delete`}
            />
          )}
        </Card>
      )}
    </>
  );
};

export const DisposisiTable = () => {
  const { id } = useParams();
  const [disposisi, setDisposisi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDisposisiId, setSelectedDisposisiId] = useState(null);
  const itemsPerPage = 10;

  const fetchDisposisi = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/show/${id}/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //const { data, totalPages } = response.data;
      setDisposisi(response.data.data || []);
    } catch (error) {
      console.error("Error fetching disposisi:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDisposisi();
  }, []);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedDisposisiId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    await fetchDisposisi();
  };

  const TableRow = ({ disposisi, index }) => {
    return (
      <tr>
        <td>{index + 1}</td> {/* Menampilkan nomor urut */}
        <td>
          {disposisi.nama_penerima}
          {disposisi.nama_penerima && disposisi.role
            ? ` [${disposisi.role}]`
            : ""}
        </td>
        <td>{disposisi.isi}</td>
        <td>{disposisi.sifat}</td>
        <td>{disposisi.catatan}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              as={Button}
              split
              variant="link"
              className="text-dark m-0 p-0"
            >
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                as={Link}
                to={`/user/disposisi/edit/${disposisi.id}/${disposisi.surat_id}`}
                className="ms-0"
              >
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item
                className="text-danger ms-0"
                onClick={() => handleDeleteClick(disposisi.id)}
              >
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
         <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No</th>
                  <th className="border-bottom">Penerima</th>
                  <th className="border-bottom">Isi</th>
                  <th className="border-bottom">Sifat</th>
                  <th className="border-bottom">Catatan</th>
                  <th className="border-bottom">Aksi</th>
                </tr>
              </thead>
              {disposisi.total === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {disposisi.map((disposisi, index) => (
                    <TableRow key={index} disposisi={disposisi} index={index} />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5"></Card.Footer>
          </Card.Body>
          {showDeleteModal && (
            <DeleteDispModal
              id={selectedDisposisiId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/disposisi/delete`}
            />
          )}
        </Card>
      )}
    </>
  );
};



export const DisposisiTrackTable = () => {
  const { id } = useParams();
  const [disposisi, setDisposisi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDisposisiId, setSelectedDisposisiId] = useState(null);
  const itemsPerPage = 10;

  const fetchDisposisi = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/tracking/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //const { data, totalPages } = response.data;
      setDisposisi(response.data.data);
    } catch (error) {
      console.error("Error fetching disposisi:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDisposisi();
  }, []);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (suratId) => {
    setSelectedDisposisiId(suratId);
    setShowDeleteModal(true);
  };

  const handleDeleteSuccess = async () => {
    await fetchDisposisi();
  };

  const TableRow = ({ disposisi, index }) => {
    //const { id, kode, nama } = disposisi;

    return (
      <tr>
        <td>{index + 1}</td> {/* Menampilkan nomor urut */}
        <td>{moment(disposisi.created_at).format("DD MMMM YYYY, HH:mm")}</td>
        <td>
          {disposisi.pejabat_nama ? (
            <>
              {disposisi.pejabat_nama}
              <br />
              {disposisi.pejabat_role ? ` [${disposisi.pejabat_role}]` : ""}
            </>
          ) : (
            "N/A"
          )}
        </td>
        <td>
          {disposisi.penerima_nama ? (
            <>
              {disposisi.penerima_nama}
              <br />
              {disposisi.penerima_role ? ` [${disposisi.penerima_role}]` : ""}
            </>
          ) : (
            "N/A"
          )}
        </td>
        <td>{disposisi.sifat}</td>
        <td>{disposisi.terbaca === "Y" ? "Sudah Dibaca" : "Belum Dibaca"}</td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
         <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No</th>
                  <th className="border-bottom">Dikirim Pada</th>
                  <th className="border-bottom">Asal Disposisi</th>
                  <th className="border-bottom">Diteruskan Pada</th>
                  <th className="border-bottom">Sifat</th>
                  <th className="border-bottom">Status</th>
                </tr>
              </thead>

              <tbody>
                {disposisi.map((disposisi, index) => (
                  <TableRow key={index} disposisi={disposisi} index={index} />
                ))}
              </tbody>
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5"></Card.Footer>
          </Card.Body>
        </Card>
      )}
    </>
  );
};
