import axios from "axios";
import parse from "html-react-parser";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BaseUrl } from "../routes/BaseUrl";
import moment from "moment";

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const location = useLocation(); // Get the full URL including query parameters
  const [errors, setErrors] = useState({});
  const [suratkeluar, setSuratKeluar] = useState([]);
  const [loading, setLoading] = useState(false);

  // Extract query parameters using URLSearchParams
  const queryParams = new URLSearchParams(location.search);
  const start = queryParams.get("start");
  const end = queryParams.get("end");

  const fetchSuratKeluar = useCallback(async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/suratkeluar/report?start=${start}&end=${end}`, // Fetch data using query params
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      setSuratKeluar(response.data);
    } catch (error) {
      console.error("Error fetching surat keluar:", error);
    } finally {
      setLoading(false);
    }
  }, [start, end]);

  useEffect(() => {
    fetchSuratKeluar();
  }, [fetchSuratKeluar]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      window.print();
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(timeoutId);
  }, []);

  const htmlContent = `
  <html>
<head>
<style type="text/css" media="print">
	table {border: solid 1px #000; border-collapse: collapse; width: 100%}
	tr { border: solid 1px #000; page-break-inside: avoid;}
	td { padding: 7px 5px; font-size: 10px}
	th {
		font-family:Arial;
		color:black;
		font-size: 11px;
		background-color:lightgrey;
	}
	thead {
		display:table-header-group;
	}
	tbody {
		display:table-row-group;
	}
	h3 { margin-bottom: -17px }
	h2 { margin-bottom: 0px }
</style>
<style type="text/css" media="screen">
	table {border: solid 1px #000; border-collapse: collapse; width: 100%}
	tr { border: solid 1px #000}
	th {
		font-family:Arial;
		color:black;
		font-size: 11px;
		background-color: #999;
		padding: 8px 0;
	}
	td { padding: 7px 5px;font-size: 10px}
	h3 { margin-bottom: -17px }
	h2 { margin-bottom: 0px }
</style>
<title>Cetak Agenda Surat Keluar</title>
</head>

<body onload="window.print()">
  <center><b style="font-size: 20px">AGENDA SURAT KELUAR</b><br>
  Dari tanggal <b>${moment(start).format(
    "DD/MM/YYYY"
  )}</b> sampai dengan tanggal <b>${moment(end).format("DD/MM/YYYY")}</b> 
  </center><br>
  
  <table border="1">
    <thead>
      <tr>
        <th width="3%">No</td>
        <th width="17%">Nomor Surat</td>
        <th width="25%">Tujuan Surat</td>
        <th width="28%">Perihal</td>
        <th width="5%">Kode Klas</td>
        <th width="7%">Tgl. Surat</td>
        <th width="10%">Pengolah</td>
      </tr>
    </thead>
    <tbody>
      ${
        suratkeluar.length > 0
          ? suratkeluar
              .map(
                (d, index) => `
               <tr>
               <td>${index + 1}</td>
               <td>${d.no_surat}</td>
               <td>${d.tujuan}</td>
               <td>${d.perihal}</td>
               <td>${d.kode}</td>
               <td>${moment(d.tgl_surat).format("DD/MM/YYYY")}</td>
               <td>${d.pengolah}</td>
              </tr>
              `
              )
              .join("")
          : "<tr><td colspan='9' style='text-align: center'>Tidak ada data</td></tr>"
      }
    </tbody>
  </table>
</body>
</html>
  `;

  return <div>{parse(htmlContent)}</div>;
};
