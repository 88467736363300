import React from 'react';
import { Navigate } from 'react-router-dom';

function RoleGuard({ children, ROLE }) {
    const role = localStorage.getItem('role');

    return role === ROLE ? <>{children}</> : <Navigate to='/' />;
}

export default RoleGuard;
