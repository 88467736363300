import React, { useState } from "react";
import { Modal, Button } from "@themesberg/react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const CommonDeleteModal = ({ id, onClose, onDelete, show, deleteUrl }) => {
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${deleteUrl}/${id}/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.data.status === "success") {
        toast.success(response.data.message);
        onDelete();
      }else {
         toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error deleting:", error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Modal centered size="sm" show={show} onHide={onClose}>
      <Modal.Body className="p-4">
        <h5 className="mb-0">Apakah Anda yakin menghapus item ini ?</h5>
      </Modal.Body>
      <Modal.Footer className="justify-content-between bg-light">
        <Button variant="secondary" onClick={onClose}>
          Batal
        </Button>
        <Button variant="danger" onClick={handleDelete} disabled={loading}>
          {loading ? "Deleting..." : "Hapus"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CommonDeleteModal;
