import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb } from "@themesberg/react-bootstrap";
import React from "react";
import { Link,useParams } from "react-router-dom";
import { DisposisiTrackTable } from "../../components/AccountTables";

export default () => {
  const { id } = useParams();

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
               <Link to={`/admin/suratmasuk/view/${id}`}>Surat Masuk</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Tracking Disposisi </Breadcrumb.Item>
          </Breadcrumb>
          <h4>Tracking Disposisi #{id}</h4>
        </div>
      </div>

      <DisposisiTrackTable />
    </>
  );
};
