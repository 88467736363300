import { faHome, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  InputGroup,
} from "@themesberg/react-bootstrap";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SuratKeluarTable } from "../../components/Tables";

export default () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchQuery.trim()) {
      // Navigate to the results page with the query as a parameter
      navigate(
        `/admin/suratkeluar/search?query=${encodeURIComponent(searchQuery)}`
      );
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
             <Link to="/admin/suratkeluar/list">Surat Keluar</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Daftar Surat </Breadcrumb.Item>
          </Breadcrumb>
          <h4>Daftar Surat Keluar</h4>
        </div>
      </div>

      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col
          xs="auto"
          className="d-flex justify-content-between ps-0 mb-4 mb-lg-0"
        >
          <div className="me-lg-3">
            <Button
              as={Link}
              to="/admin/suratkeluar/create"
              variant="secondary"
              className="text-dark me-2"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              <span>Tambah Surat </span>
            </Button>
          </div>
        </Col>

        <Col xs={12} lg={3} className="px-0 mb-0">
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Pencarian Surat"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </InputGroup>
        </Col>
      </div>

      <SuratKeluarTable />
    </>
  );
};
