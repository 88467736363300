import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseUrl } from "../routes/BaseUrl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const year = localStorage.getItem("year");
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    kodeKlasifikasi: "",
    tujuanSurat: "",
    indeksBerkas: "",
    nomorSurat: "",
    tanggalSurat: "",
    perihal: "",
    isiRingkas: "",
    fileSurat: null,
    sifatSurat: "",
    keterangan: "",
    namaPengirim: "",
    idPengirim: "",
    tahun: "",
    pengolah: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.kodeKlasifikasi)
      newErrors.kodeKlasifikasi = "Masukan Kode Klasifikasi";
    if (!formData.tujuanSurat) newErrors.tujuanSurat = "Masukan Tujuan Surat";
    if (!formData.indeksBerkas)
      newErrors.indeksBerkas = "Masukan Indeks Berkas";
    if (!formData.nomorSurat) newErrors.nomorSurat = "Masukan Nomor Surat";
    if (!formData.tanggalSurat)
      newErrors.tanggalSurat = "Masukan Tanggal Surat";
    if (!formData.perihal) newErrors.perihal = "Masukan Perihal";
    if (!formData.isiRingkas) newErrors.isiRingkas = "Masukan Isi Ringkas";
    if (!formData.sifatSurat) newErrors.sifatSurat = "Masukan Sifat Surat";
    if (!formData.pengolah) newErrors.pengolah = "Masukan Pengolah";
    if (!formData.tahun) newErrors.tahun = "Masukan Tahun";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [options, setOptions] = useState([]);
  const [noAgenda, setNoAgenda] = useState([]);
  const [loading, setLoading] = useState(false);
  const [agendaLoading, setAgendaLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(BaseUrl + `/api/users/list`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setOptions(response.data); // Assuming response.data is an array of options
        } catch (error) {
        console.error("Error fetching options:", error);
        setErrors({ tujuanSurat: "Failed to load options" });
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchNoAgenda = async () => {
      setAgendaLoading(true);
      try {
        const response = await axios.get(
          BaseUrl + `/api/suratkeluar/noagenda?year=${year}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace with your actual token
            },
          }
        );
        setNoAgenda(response.data.data); // Assuming response.data is an array of options
         setAgendaLoading(false);
      } catch (error) {
        console.error("Error fetching no agenda:", error);
        setErrors({ noAgenda: "Failed to load no agenda" });
         setAgendaLoading(false);
      }
    };

    fetchNoAgenda();
  },[]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    try {
      const response = await axios.post(
        BaseUrl + `/api/suratkeluar/store`,
        {
          kode: formData.kodeKlasifikasi,
          no_agenda: noAgenda,
          indek_berkas: formData.indeksBerkas,
          isi_ringkas: formData.isiRingkas,
          no_surat: formData.nomorSurat,
          perihal: formData.perihal,
          tgl_surat: formData.tanggalSurat,
          keterangan: formData.keterangan,
          pengolah: formData.pengolah,
          sifat: formData.sifatSurat,
          tujuan: formData.tujuanSurat,
          tahun_input: year,
          nama_pengirim: formData.namaPengirim,
          id_pengirim: formData.idPengirim,
          file_surat: formData.fileSurat,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add your token here
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        Navigate("/admin/suratkeluar/list");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const [klasifikasi, setKlasifikasi] = useState([]);
  const [value, setValue] = useState(formData.kodeKlasifikasi || "");

  const fetchKlasifikasi = async (value) => {
    try {
      const response = await axios.get(
        BaseUrl + `/api/klasifikasi/search?q=${value}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setKlasifikasi(response.data);
    } catch (error) {
      console.error("Error fetching klasifikasi:", error);
    }
  };

  const onKlasifikasiFetchRequested = ({ value }) => {
    fetchKlasifikasi(value);
  };

  const onKlasifikasiClearRequested = () => {
    setKlasifikasi([]);
  };

  const getKlasifikasiValue = (klasifikasiItem) => klasifikasiItem.kode;

  const renderKlasifikasi = (klasifikasiItem) => (
    <div>
      <b>{klasifikasiItem.kode}</b> {klasifikasiItem.nama}
    </div>
  );

  const onSuggestionSelected = (event, { suggestion }) => {
    // Set the selected suggestion into the indeksBerkas input field
    setFormData({ ...formData, indeksBerkas: suggestion.nama });
  };

  const [tujuanSurat, setTujuanSurat] = useState([]);
  const [inputValue, updateInputValue] = useState(formData.asalSurat || "");

  const fetchTujuanSurat = async (query) => {
    try {
      const response = await axios.get(
        `${BaseUrl}/api/suratkeluar/tujuan?q=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.data; // Return the data directly to use in Asal Surat
    } catch (error) {
      console.error("Error fetching Asal Surat:", error);
      return [];
    }
  };

  const onTujuanSuratFetchRequested = async ({ value }) => {
    if (value) {
      const fetchedTujuanSurat = await fetchTujuanSurat(value);
      setTujuanSurat(fetchedTujuanSurat);
    } else {
      setTujuanSurat([]);
    }
  };

  const onTujuanSuratClearRequested = () => {
    setTujuanSurat([]);
  };

  const getTujuanSuratValue = (tujuanSuratItem) => tujuanSuratItem.tujuan; // Use `nama` as the displayed value

  const renderTujuanSurat = (tujuanSuratItem) => (
    <div>{tujuanSuratItem.tujuan}</div>
  );

  const validateInput = (value) => {
    if (!value.trim()) {
      setErrors({ ...errors, tujuanSurat: "Tujuan Surat is required." });
    } else {
      setErrors({ ...errors, tujuanSurat: "" });
    }
  };

  const handleInputChange = (event, { newValue }) => {
    updateInputValue(newValue);
    setFormData({ ...formData, asalSurat: newValue }); // Validate on every change
  };

  const handleInputChange2 = (event, { newValue }) => {
    setValue(newValue);
    setFormData({ ...formData, kodeKlasifikasi: newValue }); // Validate on every change
  };

  const handleInputBlur = () => {
    validateInput(inputValue); // Validate on blur
  };

  const CreateSuratKeluar = () => {
    return (
      <>
        <h5 className="mb-4">Tambah Surat Keluar</h5>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="noAgenda">
              <Form.Label>Nomor Agenda</Form.Label>
               {agendaLoading ? (
                <Skeleton count={1} height={35} />
              ) : (
              <Form.Control
                required
                type="text"
                placeholder="Masukan Nomor Agenda"
                value={noAgenda}
                isInvalid={!!errors.noAgenda}
                disabled
              />
               )}
              <Form.Control.Feedback type="invalid">
                {errors.noAgenda}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="kodeKlasifikasi">
              <Form.Label>Kode Klasifikasi</Form.Label>
              <Autosuggest
                suggestions={klasifikasi}
                onSuggestionsFetchRequested={onKlasifikasiFetchRequested}
                onSuggestionsClearRequested={onKlasifikasiClearRequested}
                getSuggestionValue={getKlasifikasiValue}
                renderSuggestion={renderKlasifikasi}
                inputProps={{
                  placeholder: "Masukan Kode Klasifikasi",
                  value: value,
                  onChange: handleInputChange2,
                  className: "form-control",
                  isInvalid: !!errors.kodeKlasifikasi,
                }}
                onSuggestionSelected={(event, { suggestion }) => {
                  setFormData({
                    ...formData,
                    kodeKlasifikasi: suggestion.kode,
                    indeksBerkas: suggestion.nama,
                  });
                  setValue(suggestion.kode);
                  setErrors({ ...errors, kodeKlasifikasi: "" });
                }}
              />
              {errors.kodeKlasifikasi && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.kodeKlasifikasi}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="tujuanSurat">
              <Form.Label>Tujuan Surat</Form.Label>
              <Autosuggest
                suggestions={tujuanSurat}
                onSuggestionsFetchRequested={onTujuanSuratFetchRequested}
                onSuggestionsClearRequested={onTujuanSuratClearRequested}
                getSuggestionValue={getTujuanSuratValue}
                renderSuggestion={renderTujuanSurat}
                inputProps={{
                  placeholder: "Masukan Tujuan Surat",
                  value: inputValue,
                  onChange: handleInputChange,
                  className: "form-control", // Correctly placed `className`
                  onBlur: handleInputBlur,
                }}
                onSuggestionSelected={(event, { suggestion }) => {
                  setFormData({ ...formData, tujuanSurat: suggestion.tujuan });
                  updateInputValue(suggestion.tujuan);
                  setErrors({ ...errors, tujuanSurat: "" });
                }}
              />
              {errors.tujuanSurat && (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.tujuanSurat}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="indeksBerkas">
              <Form.Label>Indeks Berkas</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows="3"
                placeholder="Masukan Indeks Berkas"
                value={formData.indeksBerkas}
                onChange={(e) =>
                  setFormData({ ...formData, indeksBerkas: e.target.value })
                }
                isInvalid={!!errors.indeksBerkas}
              />
              <Form.Control.Feedback type="invalid">
                {errors.indeksBerkas}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="nomorSurat">
              <Form.Label>Nomor Surat</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Nomor Surat"
                value={formData.nomorSurat}
                onChange={(e) =>
                  setFormData({ ...formData, nomorSurat: e.target.value })
                }
                isInvalid={!!errors.nomorSurat}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nomorSurat}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="tanggalSurat">
              <Form.Label>Tanggal Surat</Form.Label>
              <Form.Control
                type="date"
                placeholder="Tanggal Surat"
                value={formData.tanggalSurat}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    tanggalSurat: e.target.value,
                  })
                }
                isInvalid={!!errors.tanggalSurat}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tanggalSurat}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="perihal">
              <Form.Label>Perihal</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Perihal"
                value={formData.perihal}
                onChange={(e) =>
                  setFormData({ ...formData, perihal: e.target.value })
                }
                isInvalid={!!errors.perihal}
              />
              <Form.Control.Feedback type="invalid">
                {errors.perihal}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="tahun">
              <Form.Label>Tahun</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Masukan Tahun"
                value={formData.tahun}
                onChange={(e) =>
                  setFormData({ ...formData, tahun: e.target.value })
                }
                isInvalid={!!errors.tahun}
              />
              <Form.Control.Feedback type="invalid">
                {errors.tahun}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="isiRingkas">
              <Form.Label>Isi Ringkas</Form.Label>
              <Form.Control
                required
                as="textarea"
                rows="3"
                placeholder="Masukan Isi Ringkas"
                value={formData.isiRingkas}
                onChange={(e) =>
                  setFormData({ ...formData, isiRingkas: e.target.value })
                }
                isInvalid={!!errors.isiRingkas}
              />
              <Form.Control.Feedback type="invalid">
                {errors.isiRingkas}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="fileSurat">
              <Form.Label>File Surat</Form.Label>
              <Form.Control
                required
                type="file"
                accept="application/pdf"
                onChange={(e) =>
                  setFormData({ ...formData, fileSurat: e.target.files[0] })
                }
                isInvalid={!!errors.fileSurat}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fileSurat}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="sifatSurat">
              <Form.Label>Sifat Surat</Form.Label>
              <Form.Control
                required
                as="select"
                value={formData.sifatSurat}
                onChange={(e) =>
                  setFormData({ ...formData, sifatSurat: e.target.value })
                }
                isInvalid={!!errors.sifatSurat}
              >
                <option value="">Pilih Sifat Surat</option>
                <option value="Segera">Segera</option>
                <option value="Penting">Penting</option>
                <option value="Biasa">Biasa</option>
                <option value="Sangat Segera">Sangat Segera</option>
                <option value="Rahasia">Rahasia</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.sifatSurat}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="keterangan">
              <Form.Label>Keterangan</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Keterangan"
                value={formData.keterangan}
                onChange={(e) =>
                  setFormData({ ...formData, keterangan: e.target.value })
                }
                isInvalid={!!errors.keterangan}
              />
              <Form.Control.Feedback type="invalid">
                {errors.keterangan}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="pengolah">
              <Form.Label>Pengolah</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Pengolah"
                value={formData.pengolah}
                onChange={(e) =>
                  setFormData({ ...formData, pengolah: e.target.value })
                }
                isInvalid={!!errors.pengolah}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pengolah}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="namaPenerima">
              <Form.Label>Nama Pengirim</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan Nama Pengirim"
                value={formData.namaPengirim}
                onChange={(e) =>
                  setFormData({ ...formData, namaPengirim: e.target.value })
                }
                isInvalid={!!errors.namaPengirim}
              />
              <Form.Control.Feedback type="invalid">
                {errors.namaPengirim}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3"></Col>{" "}
          <Col md={6} className="mb-3">
            <Form.Group id="idPengirim">
              <Form.Label>NIP / No KTP Pengirim</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Masukan NIP / No KTP Pengirim"
                value={formData.noTelpPenerima}
                onChange={(e) =>
                  setFormData({ ...formData, idPengirim: e.target.value })
                }
                isInvalid={!!errors.idPengirim}
              />
              <Form.Control.Feedback type="invalid">
                {errors.idPengirim}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="mt-3">
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleFormSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Simpan"
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleFormSubmit}>{CreateSuratKeluar()}</Form>
      </Card.Body>
    </Card>
  );
};
