import {
  faArrowDown,
  faArrowUp,
  faEdit,
  faEllipsisH,
  faEye,
  faFilePdf,
  faPen,
  faPrint,
  faSearch,
  faShare,
  faTrashAlt,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Dropdown,
  Nav,
  Pagination,
  Table,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import moment from "moment";
import "moment/locale/id";
import React, { useEffect, useState, useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  BaseUrl,
  SuratKeluarUpload,
  SuratMasukUpload,
} from "../routes/BaseUrl";
import CommonDeleteModal from "./CommonDeleteModal";
import DeleteDispModal from "./DeleteDispModal";
import DocumentViewer from "./DocumentViewer";

export const SuratMasukTable = () => {
  moment.locale("id");
  const [suratmasuk, setSuratMasuk] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);

  const handleOpenViewer = (file) => {
    setFile(file);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratMasuk = useCallback(async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/suratmasuk/list?year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      const suratMasukWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "",
        roleName: item.role?.nama || "",
      }));

      setSuratMasuk(suratMasukWithUser);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat masuk:", error);
    } finally {
      setLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    fetchSuratMasuk();
  }, [fetchSuratMasuk]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratMasuk();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
      roleName,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratMasukUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  style={{ color: "black" }}
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowDown} className="me-2" /> {dari}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="info" className="super-badge badge-lg">
                    {userName} {roleName && `[${roleName}]`}
                  </Badge>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                </div>
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-block d-lg-block d-xl-inline-flex align-items-center ms-lg-auto text-end px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              {/* Remove dropdown-menu-right to improve mobile responsiveness */}
              <Dropdown.Menu className="dropdown-menu-end">
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratmasuk/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratmasuk/edit/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger ms-0"
                  onClick={() => handleDeleteClick(id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratmasuk/disposisi/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faSearch} className="me-2" /> Lacak
                  Disposisi
                </Dropdown.Item>
                <Dropdown.Item
                  as="a"
                  href={`/admin/suratmasuk/kendali/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Kendali
                </Dropdown.Item>
                <Dropdown.Item
                  as="a"
                  href={`/admin/suratmasuk/terima/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Tanda Terima
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratmasuk.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratmasuk.map((surat, index) => (
                <TableRow key={index} surat={surat} index={index} />
              ))}
            </div>
          )}
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>

                {paginationRange.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{suratmasuk.length}</b> out of <b>{totalRecords}</b>{" "}
              entries
            </small>
          </Card.Footer>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/suratmasuk/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};

export const SuratMasukSearchTable = () => {
  moment.locale("id");
  const [suratmasuk, setSuratMasuk] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query");

  const handleOpenViewer = (file) => {
    setFile(file);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratMasukSearch = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");

    try {
      const response = await axios.get(
        `${BaseUrl}/api/suratmasuk/search?query=${searchQuery}&year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      const suratMasukWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
        roleName: item.role?.nama || "",
      }));

      setSuratMasuk(suratMasukWithUser);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat masuk:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratMasukSearch();
  }, [searchQuery, currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratMasukSearch();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
      roleName,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratMasukUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  style={{ color: "red" }}
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                <FontAwesomeIcon icon={faArrowDown} className="me-2" /> {dari}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="info" className="super-badge badge-lg">
                    {userName} {roleName && `[${roleName}]`}
                  </Badge>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                </div>
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-block d-lg-block d-xl-inline-flex align-items-center ms-lg-auto px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratmasuk/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratmasuk/edit/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger ms-0"
                  onClick={() => handleDeleteClick(id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratmasuk/kendali/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Kendali
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratmasuk/terima/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Tanda Terima
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratmasuk.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratmasuk.map((surat, index) => (
                <TableRow key={index} surat={surat} index={index} />
              ))}
            </div>
          )}
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>

                {paginationRange.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{suratmasuk.length}</b> out of <b>{totalRecords}</b>{" "}
              entries
            </small>
          </Card.Footer>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/suratmasuk/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};

export const SuratKeluarTable = () => {
  moment.locale("id");
  const [suratkeluar, setSuratKeluar] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);

  const handleOpenViewer = (file) => {
    setFile(file);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratKeluar = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/suratkeluar/list?year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      // Map through data to include user.name
      const suratKeluarWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
      }));

      setSuratKeluar(suratKeluarWithUser);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat keluar:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratKeluar();
  }, [currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratKeluar();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      tujuan,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratKeluarUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  style={{ color: "red" }}
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowUp} className="me-2" /> {tujuan}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                </div>
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-block d-lg-block d-xl-inline-flex align-items-center ms-lg-auto px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/edit/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger ms-0"
                  onClick={() => handleDeleteClick(id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/kendali/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Kendali
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/terima/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Tanda Terima
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratkeluar.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratkeluar.map((surat, index) => (
                <TableRow key={index} surat={surat} index={index} />
              ))}
            </div>
          )}
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>

                {paginationRange.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{suratkeluar.length}</b> out of <b>{totalRecords}</b>{" "}
              entries
            </small>
          </Card.Footer>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/suratkeluar/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};

export const SuratKeluarSearchTable = () => {
  moment.locale("id");
  const [suratkeluar, setSuratKeluar] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSuratId, setSelectedSuratId] = useState(null);
  const itemsPerPage = 10;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [file, setFile] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query");

  const handleOpenViewer = (file) => {
    setFile(file);
    setIsViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setIsViewerOpen(false);
    setFile(null);
  };

  const fetchSuratKeluar = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const year = localStorage.getItem("year");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/suratkeluar/search?query=${searchQuery}&year=${year}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      // Map through data to include user.name
      const suratKeluarWithUser = data.map((item) => ({
        ...item,
        userName: item.user?.name || "Unknown", // Add userName field, default to "Unknown" if not present
      }));

      setSuratKeluar(suratKeluarWithUser);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching surat keluar:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuratKeluar();
  }, [currentPage]);

  const handleDeleteClick = (suratId) => {
    setSelectedSuratId(suratId);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteSuccess = async () => {
    setCurrentPage(1);
    await fetchSuratKeluar();
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ surat, index }) => {
    const {
      id,
      no_agenda,
      dari,
      isi_ringkas,
      tujuan,
      no_surat,
      tgl_surat,
      sifat,
      kode,
      file,
      userName,
    } = surat;
    const formattedDate = moment(tgl_surat).format("DD MMMM YYYY");
    const fullFilePath = `${SuratKeluarUpload}${file}`;

    return (
      <Card border="bottom" className="hover-state rounded-0 py-3">
        <Card.Body className="d-sm-flex align-items-md-center flex-wrap flex-lg-nowrap py-0 ps-sm-0">
          <Col
            xs="auto"
            lg={1}
            className="text-start text-sm-center ps-0 ps-sm-4 mb-2 mb-sm-0"
          >
            <b>{no_agenda}</b>
            <br />
            {file && (
              <Button
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => handleOpenViewer(fullFilePath)}
              >
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="me-2"
                  style={{ color: "red" }}
                />
              </Button>
            )}
          </Col>
          <Col xs={10} lg={8} className="d-block px-0 mb-4 mb-md-0">
            <div className="mb-2">
              <h5>
                {" "}
                <FontAwesomeIcon icon={faArrowUp} className="me-2" /> {tujuan}
              </h5>
              <div className="d-block d-sm-flex">
                <div>
                  <h6 className="fw-normal mb-3 mb-sm-0">
                    {no_surat} <b>{formattedDate}</b>
                  </h6>
                </div>
                <div className="ms-sm-3">
                  <Badge bg="danger" className="super-badge badge-lg">
                    {sifat}
                  </Badge>
                </div>
              </div>
            </div>

            <div>
              <div className="fw-bold text-dark">
                <span className={`fw-normal text-gray`}>{isi_ringkas}</span>
              </div>
            </div>
          </Col>

          <Col
            xs={10}
            sm={2}
            lg={2}
            xl={2}
            className="d-block d-lg-block d-xl-inline-flex align-items-center ms-lg-auto  px-md-0"
          >
            <Dropdown className="ms-3">
              <Dropdown.Toggle
                as={Button}
                variant="link"
                className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="icon icon-sm icon-dark">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu-right">
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/view/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEye} className="me-2" /> Lihat Detail
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/edit/${id}`}
                  className="ms-0"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-danger ms-0"
                  onClick={() => handleDeleteClick(id)}
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Delete
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/kendali/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Kendali
                </Dropdown.Item>
                <Dropdown.Item
                  as={Link}
                  to={`/admin/suratkeluar/terima/${id}`}
                  className="ms-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPrint} className="me-2" /> Cetak
                  Surat Tanda Terima
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card className="table-wrapper table-responsive shadow-sm">
          {suratkeluar.length === 0 ? (
            <div className="text-center pt-3">Tidak ada data</div>
          ) : (
            <div>
              {suratkeluar.map((surat, index) => (
                <TableRow key={index} surat={surat} index={index} />
              ))}
            </div>
          )}
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
            <Nav>
              <Pagination className="mb-2 mb-lg-0">
                <Pagination.Prev
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </Pagination.Prev>

                {paginationRange.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </Nav>
            <small className="fw-bold">
              Showing <b>{suratkeluar.length}</b> out of <b>{totalRecords}</b>{" "}
              entries
            </small>
          </Card.Footer>

          {/* Delete modal */}
          {showDeleteModal && (
            <CommonDeleteModal
              id={selectedSuratId}
              onClose={handleCloseDeleteModal}
              onDelete={handleDeleteSuccess}
              show={showDeleteModal}
              deleteUrl={`${BaseUrl}/api/suratkeluar/delete`}
            />
          )}

          {isViewerOpen && (
            <DocumentViewer file={file} onClose={handleCloseViewer} />
          )}
        </Card>
      )}
    </>
  );
};

export const KlasifikasiTable = () => {
  const [klasifikasi, setKlasifikasi] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedKlasifikasiId, setSelectedKlasifikasiId] = useState(null);
  const itemsPerPage = 10;

  const fetchKlasifikasi = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/klasifikasi/list?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data, totalPages } = response.data;
      setKlasifikasi(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching klasifikasi:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKlasifikasi();
  }, [currentPage]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ klasifikasi, index }) => {
    const { id, kode, nama } = klasifikasi;

    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>{kode}</td>
        <td>{nama}</td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Kode</th>
                  <th className="border-bottom">Nama</th>
                </tr>
              </thead>
              {klasifikasi.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {klasifikasi.map((klasifikasi, index) => (
                    <TableRow
                      key={index}
                      klasifikasi={klasifikasi}
                      index={index}
                    />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{klasifikasi.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export const KlasifikasiSearchTable = () => {
  const [klasifikasi, setKlasifikasi] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedKlasifikasiId, setSelectedKlasifikasiId] = useState(null);
  const itemsPerPage = 10;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query");

  const fetchKlasifikasi = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BaseUrl}/api/klasifikasi/searchdetail?query=${searchQuery}&page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response.data;
      setKlasifikasi(data);
      setTotalPages(response.data.last_page);
      setTotalRecords(response.data.total);
    } catch (error) {
      console.error("Error fetching klasifikasi:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKlasifikasi();
  }, [currentPage, searchQuery]);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const maxPagesToShow = 5;

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages, start + maxPagesToShow - 1);

    const adjustedStart = Math.max(1, end - maxPagesToShow + 1);
    return [...Array(end - adjustedStart + 1).keys()].map(
      (i) => adjustedStart + i
    );
  };

  const paginationRange = getPaginationRange();

  const TableRow = ({ klasifikasi, index }) => {
    const { id, kode, nama } = klasifikasi;

    return (
      <tr>
        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
        <td>{kode}</td>
        <td>{nama}</td>
      </tr>
    );
  };

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm"
        >
          <Card.Body className="pt-0">
            <Table hover className="user-table align-items-center">
              <thead>
                <tr>
                  <th className="border-bottom">No.</th>
                  <th className="border-bottom">Kode</th>
                  <th className="border-bottom">Nama</th>
                </tr>
              </thead>
              {klasifikasi.length === 0 ? (
                <div className="text-center pt-3">Tidak ada data</div>
              ) : (
                <tbody>
                  {klasifikasi.map((klasifikasi, index) => (
                    <TableRow
                      key={index}
                      klasifikasi={klasifikasi}
                      index={index}
                    />
                  ))}
                </tbody>
              )}
            </Table>
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between mb-5">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Pagination.Prev>

                  {paginationRange.map((page) => (
                    <Pagination.Item
                      key={page}
                      active={page === currentPage}
                      onClick={() => setCurrentPage(page)}
                    >
                      {page}
                    </Pagination.Item>
                  ))}

                  <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{klasifikasi.length}</b> out of <b>{totalRecords}</b>{" "}
                entries
              </small>
            </Card.Footer>
          </Card.Body>
        </Card>
      )}
    </>
  );
};






