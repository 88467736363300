import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Card, Table } from "@themesberg/react-bootstrap";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BaseUrl } from "../../routes/BaseUrl";

export default () => {
  const { id,suratId } = useParams();
  const [disposisiDetails, setDisposisiDetails] = useState(null);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");

  const markDisposisiAsRead = async (disposisiId) => {
    try {
      const response = await axios.get(
        `${BaseUrl}/api/disposisi/markread/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass Bearer token if neede
          },
        }
      );

      if (response.data.status === "success") {
      } else if (response.data.status === "already_read") {
      }
    } catch (error) {
      console.error("Error marking disposisi as read:", error);
    }
  };

  useEffect(() => {
    // Call the function to mark as read when the component mounts
    markDisposisiAsRead(id);
  }, []);

  useEffect(() => {
    const fetchDisposisi = async () => {
      try {
        const response = await axios.get(
          BaseUrl + `/api/disposisi/dispshow/${id}/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace with your actual token
            },
          }
        );
        //console.log(response.data.data);
        setDisposisiDetails(response.data.data); // Assuming response.data is an array of options
      } catch (error) {
        console.error("Error fetching disposisi:", error);
      }
    };

    fetchDisposisi();
  }, [id]);

  if (!disposisiDetails) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`/user/suratmasuk/view/${suratId}`}>Surat Masuk</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Baca disposisi #{disposisiDetails.id}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Detail Disposisi</h4>
        </div>
      </div>

      <Card border="light" className="bg-white shadow-sm">
        <Card.Body>
          <div className="table-responsive">
            <Table hover className="mb-1">
              <tbody>
                <tr>
                  <td >
                    <strong>Perihal Surat</strong>
                  </td>
                  <td>{disposisiDetails.suratMasuk.perihal}</td>
                </tr>
                <tr>
                  <td >
                    <strong>Asal Disposisi</strong>
                  </td>
                  <td>{disposisiDetails.pengirim.name}</td>
                </tr>
                <tr>
                  <td >
                    <strong>Dikirim Pada</strong>
                  </td>
                  <td>
                    {moment(disposisiDetails.created_at).format(
                      "DD MMMM YYYY, HH:mm "
                    )}
                  </td>
                </tr>
                <tr>
                  <td >
                    <strong>Isi Disposisi</strong>
                  </td>
                  <td>{disposisiDetails.isi}</td>
                </tr>
                <tr>
                  <td >
                    <strong>Sifat Disposisi</strong>
                  </td>
                  <td>
                    {disposisiDetails.sifat}
                  </td>
                </tr>
                <tr>
                  <td >
                    <strong>Catatan</strong>
                  </td>
                  <td>{disposisiDetails.catatan}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
