import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Button, Col } from "@themesberg/react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { RoleTable } from "../../components/AccountTables";

export default () => {
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
             <Link to="/admin/users/role">Jabatan</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Daftar Jabatan </Breadcrumb.Item>
          </Breadcrumb>
          <h4>Daftar Jabatan</h4>
        </div>
      </div>

      <div className="d-lg-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <Col
          xs="auto"
          className="d-flex justify-content-between ps-0 mb-4 mb-lg-0"
        >
          <div className="me-lg-3">
            <Button
              as={Link}
              to="/admin/users/role/create"
              variant="secondary"
              className="text-dark me-2"
            >
              <FontAwesomeIcon icon={faPlus} className="me-2" />
              <span>Tambah Jabatan</span>
            </Button>
          </div>
        </Col>

        <Col xs={12} lg={3} className="px-0 mb-0"></Col>
      </div>

      <RoleTable />
    </>
  );
};
