import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseUrl } from "../routes/BaseUrl";

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    penerima: "",
    pemberi: "",
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get(BaseUrl + `/api/users/list`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setOptions(response.data); // Assuming response.data is an array of options
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchDisposisiAccess = async () => {
      try {
        const response = await axios.get(
          BaseUrl + `/api/disposisi/accshow/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Replace with your actual token
            },
          }
        );
        setFormData(response.data); // Assuming response.data is an array of options
      } catch (error) {
        console.error("Error fetching user account:", error);
      }
    };

    fetchDisposisiAccess();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await axios.post(
        BaseUrl + `/api/disposisi/accupdate/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add your token here
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        toast.success(response.data.message);
        Navigate(`/admin/users/disposisi/edit/${id}`);
      } else if (response.data.status == "failed") {
        toast.error(response.data.message);
        Navigate(`/admin/users/disposisi/edit/${id}`);
      } else {
        toast.error(response.data);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const UserPeriode = () => {
    return (
      <>
        <h5 className="mb-4">Edit Akses Disposisi</h5>

        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="nama">
              <Form.Label>Pejabat Pemberi</Form.Label>
              <Form.Control
                required
                as="select"
                value={formData.pemberi}
                onChange={(e) =>
                  setFormData({ ...formData, pembuat: e.target.value })
                }
                isInvalid={!!errors.pembuat}
              >
                <option value="">Pilih Akun</option>
                {options.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                    {option.periode.length > 0
                      ? option.periode.map((periode) => (
                          <div key={periode.id}> [ {periode.role.nama} ]</div>
                        ))
                      : " - "}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.pembuat}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="role">
              <Form.Label>Pejabat Penerima</Form.Label>
              <Form.Control
                required
                as="select"
                value={formData.penerima}
                onChange={(e) =>
                  setFormData({ ...formData, penerima: e.target.value })
                }
                isInvalid={!!errors.penerima}
              >
                {options.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                    {option.periode.length > 0
                      ? option.periode.map((periode) => (
                          <div key={periode.id}> [ {periode.role.nama} ]</div>
                        ))
                      : " - "}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.penerima}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="mt-3">
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleFormSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Simpan"
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleFormSubmit}>{UserPeriode()}</Form>
      </Card.Body>
    </Card>
  );
};
