import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "@themesberg/react-bootstrap";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseUrl } from "../routes/BaseUrl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const GeneralInfoForm = () => {
  const token = localStorage.getItem("token");
  const year = localStorage.getItem("year");
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    nama: "",
    role: "",
    startDate: "",
    endDate: "",
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.nama) newErrors.nama = "Pilih Akun";
    if (!formData.role) newErrors.role = "Pilih Role";
    if (!formData.startDate) newErrors.startDate = "Masukan Tanggal Mulai";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [roleoptions, setRoleOptions] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [roleLoading, setRoleLoading] = useState(false);
  

  useEffect(() => {
    const fetchOptions = async () => {
      setUserLoading(true);
      try {
        const response = await axios.get(BaseUrl + `/api/users/list`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setOptions(response.data); // Assuming response.data is an array of options
        setUserLoading(false);
      } catch (error) {
        console.error("Error fetching options:", error);
        setUserLoading(false);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      setRoleLoading(true);
      try {
        const response = await axios.get(BaseUrl + `/api/role/all`, {
          headers: {
            Authorization: `Bearer ${token}`, // Replace with your actual token
          },
        });
        setRoleOptions(response.data); // Assuming response.data is an array of options
        setRoleLoading(false);
      } catch (error) {
        console.error("Error fetching roles:", error);
        setRoleLoading(false);
      }
    };

    fetchRoles();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await axios.post(
        BaseUrl + `/api/periode/store`,
        {
          nama: formData.nama,
          role: formData.role,
          startDate: formData.startDate,
          endDate: formData.endDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add your token here
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status === "success") {
        toast.success(response.data.message);
        Navigate("/admin/users/periode");
      } else {
        toast.error(response.data.message);
        Navigate("/admin/users/periode");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const CreateUserPeriode = () => {
    return (
      <>
        <h5 className="mb-4">Tambah Periode Jabatan</h5>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="nama">
              <Form.Label>Nama Akun</Form.Label>
               {userLoading ? (
                <Skeleton count={1} height={35} />
              ) : (
              <Form.Control
                required
                as="select"
                value={formData.nama}
                onChange={(e) =>
                  setFormData({ ...formData, nama: e.target.value })
                }
                isInvalid={!!errors.nama}
              >
                <option value="">Pilih Akun</option>
                {options.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Control>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.nama}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="role">
              <Form.Label>Jabatan</Form.Label>
              {roleLoading ? (
                <Skeleton count={1} height={35} />
              ) : (
              <Form.Control
                required
                as="select"
                value={formData.roles}
                onChange={(e) =>
                  setFormData({ ...formData, role: e.target.value })
                }
                isInvalid={!!errors.role}
              >
                <option value="">Pilih Jabatan</option>
                {roleoptions.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.nama}
                  </option>
                ))}
              </Form.Control>
               )}
              <Form.Control.Feedback type="invalid">
                {errors.hakAkses}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-3">
            <Form.Group id="startDate">
              <Form.Label>Tanggal Mulai</Form.Label>
              <Form.Control
                type="date"
                placeholder="Tanggal Mulai"
                value={formData.startDate}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    startDate: e.target.value,
                  })
                }
                isInvalid={!!errors.startDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.startDate}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6} className="mb-3">
            <Form.Group id="endDate">
              <Form.Label>
                Tanggal Akhir (Kosongkan jika masih menjabat)
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="Tanggal Akhir"
                value={formData.endDate}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    endDate: e.target.value,
                  })
                }
                isInvalid={!!errors.endDate}
              />
              <Form.Control.Feedback type="invalid">
                {errors.endDate}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <div className="mt-3">
          <Button
            variant="primary"
            disabled={loading}
            onClick={handleFormSubmit}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Simpan"
            )}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleFormSubmit}>{CreateUserPeriode()}</Form>
      </Card.Body>
    </Card>
  );
};
